import { isUnlimitedLimit, zeroesToK } from '@pumpkincare/plans';
import { getQuotePartner, useQuote } from '@pumpkincare/quotes';
import { EMBARK } from '@pumpkincare/shared';
import { LegalBody } from '@pumpkincare/shared/ui';

import styles from './protect.css';

function Protect({ discount, pet, isThereDiscount, finalPrice, isLoggedIn }) {
  const { data: quoteData } = useQuote();
  const partner = getQuotePartner(quoteData);

  function formatDiscounts() {
    if (isThereDiscount) {
      if (isLoggedIn) return [{ name: '10% Multi-pet Discount Applied' }];

      return discount.discounts.map(discount => {
        if (discount.type === 'PriorityCode' && partner === EMBARK) {
          return {
            ...discount,
            name: `${discount.percentage}% Embark Customer Discount Applied`,
          };
        }

        if (discount.name.match(/priority/i)) {
          return {
            ...discount,
            name: `Priority Code ${discount.percentage}% off`,
          };
        }

        return discount;
      });
    }

    return [];
  }

  const coverageLimit = isUnlimitedLimit(pet.annual_limit)
    ? pet.annual_limit
    : `$${zeroesToK(pet.annual_limit)}`;

  return (
    <div>
      <div className={styles.insuranceTitleDiv}>
        <LegalBody isBold>Pumpkin Pet Insurance</LegalBody>

        <LegalBody
          isBold
          data-testid={'insurance-price-checkout-page'}
          className={styles.insuranceTitlePriceWithoutDiscount}
        >
          {finalPrice}
        </LegalBody>
      </div>

      <div className={styles.verticalLine}>
        <div className={styles.divFlexRow}>
          <div style={{ marginRight: '10px' }}>
            <LegalBody isBold className={styles.legalBodyMargin}>
              90%
            </LegalBody>
            <LegalBody isBold className={styles.legalBodyMargin}>
              ${pet.deductible}
            </LegalBody>
            <LegalBody isBold className={styles.legalBodyMargin}>
              {coverageLimit}
            </LegalBody>
          </div>

          <div>
            <LegalBody className={styles.legalBodyMargin}>
              Reimbursement Rate
            </LegalBody>
            <LegalBody className={styles.legalBodyMargin}>
              Annual Deductible
            </LegalBody>
            <LegalBody className={styles.legalBodyMargin}>
              Annual Coverage Limit
            </LegalBody>
          </div>
        </div>
      </div>

      {isThereDiscount
        ? formatDiscounts().map((disc, index) => {
            return (
              <LegalBody isBold className={styles.discount} key={index}>
                <span className={styles.discountText}>{disc.name}</span>
              </LegalBody>
            );
          })
        : null}
    </div>
  );
}

export default Protect;
