import { useEffect } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import Store from 'store';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import { usePetPlanData } from '@pumpkincare/plans';
import {
  getPetPolicy,
  getQuoteActivePet,
  getQuoteId,
  getQuoteIdentityId,
  getQuoteVetId,
  useMutatePetPolicy,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import {
  DOG,
  IDENTITY_LOCAL_STORAGE_KEY,
  UP_TO_5_MONTHS,
  UP_TO_11_MONTHS,
  useBooleanInput,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';

import QuoteEditModal from '../../quote-edit-modal';
import InsurancePicker from './insurance-picker';
import InsurancePickerBundle from './insurance-picker-bundle';

import styles from './picker.css';

function Picker({
  onAddToCart,
  buttonText = 'Add to Cart',
  headerText,
  headerSplit,
}) {
  const { punks947PlanPageDesignOptimization, punks1233PlanBundles } = useFlags();

  const [isEditQuoteOpen, toggleEditQuoteOpen] = useBooleanInput(false);
  const { activeId } = useQuotesActiveId();

  const { data: quoteData } = useQuote();
  const identityId =
    getQuoteIdentityId(quoteData) || Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const quoteId = getQuoteId(quoteData);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const { petName } = activePet;

  const { mutate: mutatePetPolicy } = useMutatePetPolicy();

  const { isReady, data: petPlanData } = usePetPlanData({
    petId: activePet.id,
    identityId,
    vetId,
  });

  const {
    activeValues,
    insurancePicker: { activePrice },
  } = petPlanData;

  const profileImage = `/assets/images/photos/plan-selection/${getProfileImage(
    activePet?.petAge,
    activePet?.petBreedSpecies
  )}`;

  const costBlurbClassName = classNames(styles.costBlurbImage, {
    [styles.costBlurbImageBenji]:
      !punks947PlanPageDesignOptimization || punks1233PlanBundles,
  });

  const pickerSectionClassName = classNames({
    [styles.pickerSectionGrid]: !punks947PlanPageDesignOptimization,
    [styles.pickerSectionGridOptm]: punks947PlanPageDesignOptimization,
    [styles.pickerSectionGridBundles]:
      punks1233PlanBundles && punks947PlanPageDesignOptimization,
  });

  const compGrid = classNames(styles.pickerCompGrid, {
    [styles.pickerCompGridBundle]: punks1233PlanBundles,
  });

  const profileModuleImageClass = classNames(styles.profileModuleImage, {
    [styles.profileModuleImageBundle]: punks1233PlanBundles,
  });

  const rootClassName = classNames(styles.root, {
    [styles.rootBundle]: punks1233PlanBundles,
  });

  function getProfileImage(petAge, petBreedSpecies) {
    if (petBreedSpecies === DOG) {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-puppy.png'
        : 'profile-image-dog.png';
    } else {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-kitten.png'
        : 'profile-image-cat.png';
    }
  }

  function handleInsuranceChange({ key, value }) {
    mutatePetPolicy({
      pet: { ...activePet, [key]: value },
    });
  }

  function handleInsuranceChangeBundle({ annual_limit, deductible }) {
    mutatePetPolicy({
      pet: { ...activePet, annual_limit, deductible },
    });
  }

  useEffect(() => {
    getPetPolicy(quoteId, activePet.id)
      // mutate pet policy selection with recommended options right away
      .then(() => mutatePetPolicy({ pet: { ...activePet, ...activeValues } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsTrack({
      event: INITIATE_EXPERIMENT,
      category: 'Insurance Bundles',
      label: punks1233PlanBundles ? 'b_test' : 'a_control',
    });
  }, [punks1233PlanBundles]);

  return (
    <div className={rootClassName}>
      {isEditQuoteOpen ? <QuoteEditModal onClose={toggleEditQuoteOpen} /> : null}
      {punks947PlanPageDesignOptimization ? (
        <div className={styles.header}>
          <h2>{petName}'s plan</h2>

          <span className={classNames(Typography.body2, styles.headerText)}>
            {headerText}
          </span>
          {punks1233PlanBundles ? (
            <LegalBody
              isBold
              className={styles.editLink}
              onClick={toggleEditQuoteOpen}
            >
              Edit pet details
            </LegalBody>
          ) : null}
        </div>
      ) : null}

      <div className={pickerSectionClassName}>
        {punks947PlanPageDesignOptimization ? null : (
          <div className={styles.headerGrid}>
            <h3 className={classNames(Typography.h3, styles.headerStart)}>
              {headerSplit.start}
            </h3>{' '}
            <h4 className={classNames(Typography.h4, styles.headerSplit)}>
              {headerSplit.adjectives}
            </h4>
          </div>
        )}
        {punks1233PlanBundles ? null : (
          <div className={styles.imagesGrid}>
            <div className={styles.imagesWrapper}>
              <img src={profileImage} alt='' className={styles.profileModuleImage} />

              <div className={costBlurbClassName}>
                <img
                  src='/assets/images/iconImages/piggy-bank.png'
                  alt=''
                  className={styles.piggyImg}
                />

                <Body2>
                  Your {activePet.petBreedSpecies.toLowerCase()}’s breed, age & ZIP +
                  the reimbursement rate, annual limit & deductible you choose all
                  factor into your cost!
                </Body2>
              </div>
            </div>
          </div>
        )}

        <div className={compGrid}>
          <div className={styles.pickerContainer}>
            {isReady &&
            punks1233PlanBundles &&
            punks947PlanPageDesignOptimization ? (
              <InsurancePickerBundle
                onAddToCart={onAddToCart}
                buttonText={buttonText}
                petPlanData={petPlanData}
                onChange={handleInsuranceChangeBundle}
                pet={activePet}
                activePrice={activePrice}
              />
            ) : null}

            {isReady && !punks1233PlanBundles ? (
              <InsurancePicker
                onAddToCart={onAddToCart}
                buttonText={buttonText}
                petPlanData={petPlanData}
                onChange={handleInsuranceChange}
                pet={activePet}
              />
            ) : null}
          </div>
        </div>
        {punks947PlanPageDesignOptimization && punks1233PlanBundles ? (
          <>
            <div className={styles.imagesGridBundle}>
              <img src={profileImage} alt='' className={profileModuleImageClass} />
            </div>

            <div className={styles.imagesGridBlurb}>
              <div className={costBlurbClassName}>
                <img
                  src='/assets/images/iconImages/piggy-bank.png'
                  alt=''
                  className={styles.piggyImg}
                />

                {punks1233PlanBundles ? (
                  <LegalBody>
                    Every Pumpkin plan has a <b>90% reimbursement rate</b> because we
                    think ALL pet parents deserve maximum cash back on eligible
                    veterinary bills.
                  </LegalBody>
                ) : (
                  <Body2>
                    Your {activePet.petBreedSpecies.toLowerCase()}’s breed, age & ZIP
                    + the reimbursement rate, annual limit & deductible you choose
                    all factor into your cost!
                  </Body2>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

Picker.defaultProps = {
  buttonText: 'Add to Cart',
  headerSplit: {},
  image: '',
  headerText: '',
};

Picker.propTypes = {
  buttonText: PropTypes.string,
  headerSplit: PropTypes.shape({
    start: PropTypes.string,
    adjectives: PropTypes.string,
  }),
  image: PropTypes.string,
  headerText: PropTypes.string,
};
export default Picker;
