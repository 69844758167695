import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  getProductCanUpsellPrevent,
  getProductHasPrevent,
  useProductsByState,
} from '@pumpkincare/plans';
import { getQuotePartner, getQuotePolicyState, useQuote } from '@pumpkincare/quotes';
import { DOG } from '@pumpkincare/shared';
import { Body2, ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import BundleLevers from './bundle-levers';
import InsuranceModal from './insurance-modal';

import styles from './insurance-picker-bundle.css';

import stethoscopePng from './stethoscope.png';

function InsurancePickerBundle({
  petPlanData,
  onChange,
  buttonText,
  onAddToCart,
  pet,
  activePrice,
}) {
  const { data: quoteData } = useQuote();
  const partner = getQuotePartner(quoteData);

  const { punks947PlanPageDesignOptimization } = useFlags();

  const policyState = getQuotePolicyState(quoteData);
  const { data: productsByStateData } = useProductsByState(policyState);

  const productHasPrevent = getProductHasPrevent(productsByStateData); //Maine
  const productCanUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const bundle_mapping = {
    cheaper: {
      name: 'Wallet Friendly',
      deductible: 'Deductible1000',
      annual_limit: pet.petBreedSpecies === DOG ? 'Limit10000' : 'Limit7000',
    },
    preselected: {
      // Replacement for Recommendation
      name: 'Most Popular',
      deductible: pet.petBreedSpecies === DOG ? 'Deductible500' : 'Deductible250',
      annual_limit: pet.petBreedSpecies === DOG ? 'Limit10000' : 'Limit7000',
    },
    higher_cost: {
      name: 'Above and Beyond',
      deductible: 'Deductible500',
      annual_limit: 'LimitUnlimited',
    },
  };

  const modalData = {
    title: 'Reimbursement Rate',
    sectionItems: [
      {
        title: 'What is it?',
        copy: (
          <>
            This is the percentage of an eligible vet bill your pet insurance plan
            will reimburse you for.
          </>
        ),
      },
      {
        title: 'Why 90%?',
        copy: (
          <>
            Because we think every pet parent deserves maximum cash back on eligible
            vet bills for accidents & illnesses. And that means 90% (not UP TO 90%)
            cash back!
          </>
        ),
      },
    ],
    footer: {
      title: 'Arf! Arf!',
      copy: '90% cash back on eligible vet bills kicks in after your annual deductible.',
      img: '/assets/images/iconImages/pets-saving.png',
      items: [
        {
          title: 'Tip!',
          copy: (
            <>
              90% cash back on eligible vet bills kicks in after your annual
              deductible.
            </>
          ),
        },
      ],
    },
  };

  return (
    <div className={styles.root}>
      <div className={styles.containerBundle}>
        <Body2 isBold className={styles.pickerHeader}>
          90% reimbursement rate for all plans
          <InsuranceModal
            modalData={modalData}
            classes={{ modalTitle: styles.modalTitle, modalIcon: styles.modalIcon }}
          />
        </Body2>

        <div className={styles.leversContainer}>
          <BundleLevers
            petPlanData={petPlanData}
            quoteData={quoteData}
            partner={partner}
            onChange={onChange}
            pet={pet}
            bundleMapping={bundle_mapping}
          />
        </div>

        <button
          className={classNames(ButtonStyles.primaryLarge, styles.addToCartButton)}
          data-testid='select-this-plan'
          onClick={onAddToCart}
        >
          {`${buttonText} – $${activePrice} / mo`}
        </button>
      </div>

      {punks947PlanPageDesignOptimization &&
      productHasPrevent &&
      productCanUpsellPrevent ? (
        <div className={styles.wellnessCallout}>
          <div>
            <img alt='' src={stethoscopePng} className={styles.stethoscope} />
          </div>

          <div>
            <span className={Typography.legalBody}>
              <b>Want refunds on wellness care?</b>
              <br />
              Add Preventive Essentials on the next page! It's not insurance, but an
              optional wellness package you can add to your plan.
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
InsurancePickerBundle.defaultProps = {
  petPlanData: {},
  onChange: () => {},
  buttonText: 'Add to Cart',
  onAddToCart: () => {},
  pet: {},
  activePrice: '0.00',
};

InsurancePickerBundle.propTypes = {
  petPlanData: PropTypes.object,
  onChange: PropTypes.func,
  buttonText: PropTypes.string,
  onAddToCart: PropTypes.func,
  pet: PropTypes.object,
  activePrice: PropTypes.string,
};

export default InsurancePickerBundle;
